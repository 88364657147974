import React, { useRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;

function DonationComponent() {

    let url = "http://217.160.117.44:8181/"

    const audioIframeRef = useRef(null);
    const [socket, setSocket] = useState(null);

    let playing = false

    useEffect(() => {
        function initializeWebSocket() {
            if (socket && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
                return;
            }

            const newSocket = new WebSocket(`ws://217.160.117.44:8181/api/websocket`);

            newSocket.addEventListener('open', () => {
                console.log("WebSocket connection successfully established.");
            });

            newSocket.addEventListener('message', (event) => {
                if(event.data === 'play') {
                    fetchAndPlayAudio();
                }
                if(event.data === 'skip') {

                    /**
                     * skip the current tts
                     */
                    if(playing) {
                        const audioIframe = audioIframeRef.current;

                        if (!audioIframe || !audioIframe.contentWindow || !audioIframe.contentWindow.document) {
                            console.error("Audio iframe or its document is not available");
                            return;
                        }

                        const iframeDocument = audioIframe.contentWindow.document;

                        const existingAudio = iframeDocument.getElementsByTagName('audio')[0];
                        if (existingAudio) {
                            existingAudio.remove();
                        }
                    }

                }
            });

            newSocket.addEventListener('close', () => {
                console.log("WebSocket disconnected. Attempting to reconnect in 5 seconds...");
                setTimeout(initializeWebSocket, 5000);
            });

            setSocket(newSocket);
        }

        // Define fetchAndPlayAudio inside useEffect
        function fetchAndPlayAudio() {
            fetch(`${url}/api/audioStream`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Connection error while attempting to download audio file');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const audioUrl = URL.createObjectURL(blob);
                    createAudioElement(audioUrl);
                })
                .catch(error => {
                    console.error("Error while downloading audio URL:", error);
                });
        }

        initializeWebSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    function createAudioElement(audioUrl) {
        const audioIframe = audioIframeRef.current;

        if (!audioIframe || !audioIframe.contentWindow || !audioIframe.contentWindow.document) {
            console.error("Audio iframe or its document is not available");
            return;
        }

        const iframeDocument = audioIframe.contentWindow.document;

        const existingAudio = iframeDocument.getElementsByTagName('audio')[0];
        if (existingAudio) {
            existingAudio.remove();
        }

        let audioElement = iframeDocument.createElement('audio');
        audioElement.controls = false;
        audioElement.src = audioUrl;

        iframeDocument.body.appendChild(audioElement);

        audioElement.addEventListener('loadeddata', () => {
            audioElement.play().catch(error => {
                console.error("Error while playing audio URL:", error);
            });
        });

        playing = true

        fetch(`${url}/api/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("playing")
        }).catch(error => {
            console.error("error while fetching to backend:", error)
        })

        audioElement.onended = function () {
            playing = false
            console.log("playback ended")
            fetch(`${url}/api/status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify("ending")
            }).catch(error => {
                console.error("error while fetching to backend:", error)
            })

        };
    }


    return (
        <div>
            <iframe
                ref={audioIframeRef}
                title="Audio Playback"
                allow="autoplay"
                hidden
                style={{ display: 'none' }}
            ></iframe>
        </div>
    );
}

export default DonationComponent;
